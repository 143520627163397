<template>
  <v-app>
    <v-app-bar app color="primary" elevate-on-scroll>
      <div
        @click="$router.push('/')"
        style="width: 100vw; cursor: pointer"
        class="d-flex align-center justify-center"
      >
        <v-img
          alt="Jan Overmeer logo"
          class="shrink mr-2"
          contain
          src="@/assets/logo_small.png"
          height="50"
          max-width="50"
        />
        <p style="font-size: 14pt; color: white; margin: 0">Jan Overmeer</p>
      </div>

      <template v-slot:extension>
        <v-tabs v-model="tab" centered dark v-if="$vuetify.breakpoint.smAndUp">
          <v-tab
            :tab-value="0"
            style="text-transform: none"
            @click="$router.push('/')"
            >Home</v-tab
          >
          <v-tab
            :tab-value="1"
            style="text-transform: none"
            @click="$router.push('/over')"
            >Over Jan Overmeer</v-tab
          >
          <v-tab
            :tab-value="2"
            style="text-transform: none"
            @click="$router.push('/diensten')"
            >Diensten</v-tab
          >
          <v-tab
            :tab-value="3"
            style="text-transform: none"
            @click="$router.push('/contact')"
            >Contact</v-tab
          >
        </v-tabs>
        <div v-else class="d-flex justify-center" style="width: 100vw">
          <v-btn @click="drawer = !drawer" icon dark
            ><v-icon>mdi-menu</v-icon></v-btn
          >
        </div>
      </template>
    </v-app-bar>
    <v-navigation-drawer v-model="drawer" app temporary>
      <div
        @click="$router.push('/')"
        class="primary d-flex flex-column align-center pa-4"
      >
        <v-img
          alt="Jan Overmeer logo"
          class="shrink mr-2"
          contain
          src="@/assets/logo_small.png"
          height="50"
          max-width="50"
        />
      </div>
      <v-list nav dense>
        <v-list-item-group v-model="tab" active-class="primary--text">
          <v-list-item @click="$router.push('/')">
            <v-list-item-title>Home</v-list-item-title>
          </v-list-item>

          <v-list-item @click="$router.push('/over')">
            <v-list-item-title>Over Jan Overmeer</v-list-item-title>
          </v-list-item>

          <v-list-item @click="$router.push('/diensten')">
            <v-list-item-title>Diensten</v-list-item-title>
          </v-list-item>

          <v-list-item @click="$router.push('/contact')">
            <v-list-item-title>Contact</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    <v-main class="secondary">
      <transition name="slide-fade" mode="out-in">
        <router-view />
      </transition>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "App",
  watch: {
    $route: {
      handler: function (val) {
        console.log(val);
        if (val.path.includes("/diensten")) {
          this.tab = 2;
        } else if (val.path.includes("/over")) {
          this.tab = 1;
        } else if (val.name.includes("home")) {
          this.tab = 0;
        } else if (val.path.includes("/contact")) {
          this.tab = 3;
        }
      },
      deep: true,
      immediate: true,
    },
  },
  data: () => ({
    drawer: false,
    tab: 0,
  }),
};
</script>

<style lang="scss" scoped>
.slide-fade-enter-active {
  transition: all 0.3s ease;
  transition-delay: 0.2s;
}
.slide-fade-leave-active {
  transition: all 0.2s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateX(10px);
  opacity: 0;
}
</style>
