<template>
  <div>
    <div class="subdiensten my-8">
      <v-card
        flat
        class="pa-4 d-flex flex-column align-center"
        style="gap: 15px"
      >
        <img
          src="@/assets/mediation.jpg"
          style="
            width: 100%;
            aspect-ratio: 16/10;
            object-fit: cover;
            border-radius: 4px;
          "
        />
        <h3 class="primary--text">Mediation</h3>
        <v-btn
          @click="$vuetify.goTo('#mediation', { offset: 50 })"
          color="button"
          class="primary--text font-weight-bold"
          elevation="0"
          >Lees meer</v-btn
        >
      </v-card>
      <v-card
        flat
        class="pa-4 d-flex flex-column align-center"
        style="gap: 15px"
      >
        <img
          src="@/assets/gespreksleider.jpg"
          style="
            width: 100%;
            aspect-ratio: 16/10;
            object-fit: cover;
            border-radius: 4px;
          "
        />
        <h3 class="primary--text text-center">Neutrale gespreksleider</h3>
        <v-btn
          @click="$vuetify.goTo('#gespreksleider', { offset: 50 })"
          color="button"
          class="primary--text font-weight-bold"
          elevation="0"
          >Lees meer</v-btn
        >
      </v-card>
      <v-card
        flat
        class="pa-4 d-flex flex-column align-center"
        style="gap: 15px"
      >
        <img
          src="@/assets/coaching.png"
          style="
            width: 100%;
            aspect-ratio: 16/10;
            object-fit: cover;
            border-radius: 4px;
          "
        />
        <h3 class="primary--text">Coaching</h3>
        <v-btn
          @click="$vuetify.goTo('#coaching', { offset: 50 })"
          color="button"
          class="primary--text font-weight-bold"
          elevation="0"
          >Lees meer</v-btn
        >
      </v-card>
      <v-card
        flat
        class="pa-4 d-flex flex-column align-center"
        style="gap: 15px"
      >
        <img
          src="@/assets/training.jpg"
          style="
            width: 100%;
            aspect-ratio: 16/10;
            object-fit: cover;
            border-radius: 4px;
          "
        />
        <h3 class="primary--text">Training</h3>
        <v-btn
          @click="$vuetify.goTo('#training', { offset: 50 })"
          color="button"
          class="primary--text font-weight-bold"
          elevation="0"
          >Lees meer</v-btn
        >
      </v-card>
    </div>

    <div class="white">
      <div class="mediation dienst" id="mediation">
        <div>
          <h2 class="mb-3 primary--text">Mediation</h2>
          <p>
            Als deskundige MfN register mediator gespecialiseerd in
            arbeidsmediation begeleid ik landelijk mediation trajecten in de
            non-profit sector (onderwijs, zorg, overheid) en profitsector
            (commerciële sector, bank & verzekeringen, horeca, techniek,
            agrarische sector).
          </p>
          <p style="font-weight: 500">Ik begeleid conflicten tussen:</p>
          <ul class="mb-4">
            <li>Werknemers onderling</li>
            <li>Werkgever en werknemer</li>
            <li>Cliënt en organisatie</li>
            <li>Maatschappen</li>
            <li>Compagnons</li>
            <li>Bestuursleden</li>
          </ul>

          <p style="font-weight: 500">Uitgangspunt bij mediation</p>
          <ul class="mb-4">
            <li>
              Vertrouwelijkheid : wat wordt besproken blijft vertrouwelijk
            </li>
            <li>
              Vrijwilligheid : mediation kan door elke partij worden beëindigd
            </li>
            <li>
              Verantwoordelijkheid : partijen werken actief aan een oplossing
            </li>
            <li>Onpartijdigheid : van mij als mediator</li>
          </ul>

          <p style="font-weight: 500">Opzet mediation:</p>
          <ol class="mb-4">
            <li>Intakegesprekken met partijen afzonderlijk van +/-1 uur</li>
            <li>
              Daarna gemiddeld 1 tot 3 gezamenlijke gesprekken van 2 tot 4 uur
            </li>
            <li>Afronding met afspraken die worden vastgelegd</li>
          </ol>
          <p>
            Vanuit een constructieve communicatie ben ik als mediator zuiver op
            de inhoud en heb ik hart voor de mensen aan tafel. Er is altijd
            ruimte om tijdens een mediationtraject één op één met mij in
            vertrouwen te sparren. Het uiteindelijk doel van de mediation is een
            WIN-WIN situatie te realiseren waar beide partijen uiteindelijk
            achter de oplossing kunnen staan. Met een slagingspercentage van
            meer dan 90% is mijn aanpak succesvol gebleken.
          </p>

          <v-btn
            @click="openTab('./resultaten-mediations.pdf')"
            color="button"
            class="primary--text font-weight-bold"
            elevation="0"
            >Resultaten</v-btn
          >
        </div>
        <div class="d-flex align-center">
          <img
            style="border-radius: 4px; width: 100%"
            cover
            src="@/assets/mediation.jpg"
          />
        </div>
      </div>
    </div>

    <div class="gespreksleider dienst" id="gespreksleider">
      <div>
        <h2 class="primary--text mb-3">Neutrale gespreksleider</h2>
        <p>
          Als neutrale gespreksleider maak ik moeilijk bespreekbare onderwerpen,
          eventueel in combinatie met arbeidsongeschiktheid, bespreekbaar. Via
          kompasgesprekken streven we samen naar de juiste koers. Die koers kan
          zijn:
        </p>
        <ul class="mb-4">
          <li>Duidelijkheid creëren om van daaruit verder te kunnen</li>
          <li>Het maken van concrete afspraken</li>
          <li>Het los kunnen laten van de ontstane situatie</li>
          <li>Verbeteren van de samenwerking</li>
          <li>Het voorkomen van onnodig-, kostbaar-, langdurig verzuim</li>
        </ul>
        <p style="font-weight: 500">Opzet kompasgesprek:</p>
        <ol>
          <li>Individuele gesprekken van +/- 1 uur per persoon</li>
          <li>
            Aansluitend een gezamenlijk gesprek van +/- 2 uur met betrokkenen
          </li>
        </ol>
      </div>
      <div class="d-flex align-center">
        <img
          style="border-radius: 4px; width: 100%"
          cover
          src="@/assets/gespreksleider.jpg"
        />
      </div>
    </div>

    <div class="white">
      <div class="coaching dienst" id="coaching">
        <div>
          <h2 class="mb-4 primary--text">Coaching</h2>
          <p>
            Als betrokken coach staat een aanpak op basis van gelijkwaardigheid,
            respect, empathie en vertrouwen centraal. Door een actieve
            coachstijl werken we samen aan het vergroten van (zelf)inzicht,
            bewustwording van patronen en stimuleren van effectief gedrag.
            Daarmee kunnen bewuste keuzes worden gemaakt en
            verantwoordelijkheden worden genomen. Dat komt individu en de
            eventueel organisatie ten goede. De coachvraagstukken kunnen zowel
            privé- als werk gerelateerd zijn.
          </p>
          <p>
            Wanneer ik als onafhankelijke neutrale coach een probleemsituatie
            vertrouwelijk bespreek kom ik tot de kern. Daarop kan dan adequaat
            worden geanticipeerd.
          </p>
          <p style="font-weight: 500">Opzet coachtraject:</p>
          <ol class="mb-4">
            <li>Gespreksduur +/- 1 uur</li>
            <li>Gemiddeld traject is 3 tot 4 gesprekken</li>
          </ol>
          <v-btn
            @click="openTab('./resultaten-coaching.pdf')"
            color="button"
            class="primary--text font-weight-bold"
            elevation="0"
            >Resultaten coachtrajecten</v-btn
          >
        </div>
        <div class="d-flex align-center">
          <img
            style="border-radius: 4px; width: 100%"
            cover
            src="@/assets/coaching.png"
          />
        </div>
      </div>
    </div>

    <div class="training dienst" id="training">
      <div>
        <h2 class="mb-3 primary--text">Training</h2>
        <p>
          Voor medewerkers met een verschil van inzicht, een (dreigend) conflict
          of niet optimale samenwerking is een verbindende trainer de sleutel om
          tot een sterkere samenwerking te komen. Als verbindende trainer
          herstel en versterk ik bestaande relaties door het toepassen van
          verschillende methodes zoals de DISC-methode, mediation- en coach
          kennis en vaardigheden.
        </p>
        <p>
          De DISC-methode is een erkende persoonlijkheidsanalyse die al vele
          jaren wordt gebruikt zowel bij persoonlijke- en teamontwikkeling. De
          DISC-methode geeft inzicht in jezelf als in die van jouw collega’s.
          Het ondersteunt bij het verbeteren van de onderlinge communicatie en
          samenwerking.
        </p>
      </div>
      <div class="d-flex align-center">
        <img
          style="border-radius: 4px; width: 100%"
          cover
          src="@/assets/training.jpg"
        />
      </div>
    </div>

    <Footer />
  </div>
</template>

<script>
import Footer from "@/components/FooterBox.vue";

export default {
  components: {
    Footer,
  },
  mounted() {
    if (this.$route.query.id) {
      this.$vuetify.goTo(`#${this.$route.query.id}`, { offset: 50 });
    }
  },
  methods: {
    openTab(url) {
      window.open(url, "_blank");
    },
  },
};
</script>

<style lang="scss" scoped>
.subdiensten {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 15px;
  margin: 0 auto;
  max-width: 1200px;
}

.dienst {
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 50px;
  padding: 80px 40px;
  max-width: 1200px;
  margin: auto;
  img {
    aspect-ratio: 1/1;
  }
}

@media only screen and (max-width: 480px) {
  h1 {
    font-size: 16pt;
  }
  h2 {
    font-size: 14pt;
  }
  h3 {
    font-size: 12pt;
  }
  p {
    font-size: 10pt;
  }
  .subtitle {
    font-size: 12pt;
  }
  li {
    font-size: 10pt;
  }

  .subdiensten {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    max-width: 90vw;
  }

  .dienst {
    display: flex;
    flex-wrap: wrap;
    max-width: 90vw;
    img {
      aspect-ratio: 1/1;
    }
  }
}
</style>
