import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";
import OverJan from "../views/OverJan.vue";
import Diensten from "@/views/DienstenView.vue";
import Contact from "@/views/ContactView.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/over",
    name: "Over Jan Overmeer",
    component: OverJan,
  },
  {
    path: "/diensten",
    name: "Diensten",
    component: Diensten,
  },
  {
    path: "/contact",
    name: "Contact",
    component: Contact,
  },
  { path: "/curriculum-vitae/", redirect: "/over" },
  { path: "/over-jan-overmeer/", redirect: "/over" },
  { path: "/algemeen/", redirect: "/" },
  { path: "/aanpak-die-werkt/", redirect: "/diensten" },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

export default router;
