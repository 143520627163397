import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");

var description = document.createElement("meta");
description.setAttribute("property", "og:description");
description.content =
  "Jan Overmeer: Mediation, Neutrale gespreksleider, Coaching & Training";
document.getElementsByTagName("head")[0].appendChild(description);

var title = document.createElement("meta");
title.setAttribute("property", "og:title");
title.content = "Jan Overmeer";
document.getElementsByTagName("head")[0].appendChild(title);

var image = document.createElement("meta");
image.setAttribute("property", "og:image");
image.content = "https://www.janovermeer.nl/jan.jpg";
document.getElementsByTagName("head")[0].appendChild(image);
