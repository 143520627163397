<template>
  <div class="about">
    <v-card flat class="over">
      <div class="d-flex flex-column justify-center align-start">
        <h2 class="mb-3 primary--text">Meer over Jan Overmeer</h2>
        <p class="mb-3">
          Met mijn expertise breng ik mensen of situaties die in een impasse
          terecht zijn gekomen in beweging. Jarenlange ervaring in het
          begeleiden en adviseren van individuen en groepen binnen diverse
          (non-) profitorganisaties hebben geleid tot spraakmakende resultaten
          voor
          <u
            class="primary--text"
            style="cursor: pointer"
            @click="openTab('./resultaten-mediations.pdf')"
            >mediations-</u
          >
          en
          <u
            class="primary--text"
            style="cursor: pointer"
            @click="openTab('./resultaten-coaching.pdf')"
            >coachtrajecten</u
          >. Daarnaast ben ik sparringpartner met wie u in vertrouwen
          vraagstukken kunt bespreken op individueel of organisatieniveau. Denk
          bijvoorbeeld aan persoonlijke vraagstukken, een disfunctionerende
          groep medewerkers of een moeilijke strategische keuze. Ik ben tactvol,
          empathisch, direct en resultaatgericht. Het komt de duidelijkheid voor
          betrokkenen ten goede. Dat bespaart tijd, geld en energie.
        </p>
        <v-btn
          elevation="0"
          class="primary--text font-weight-bold"
          color="button"
          @click="$router.push('/contact')"
          >Contact</v-btn
        >
      </div>
      <v-img
        style="border-radius: 3px"
        contain
        src="@/assets/header.jpg"
      ></v-img>
    </v-card>

    <Vakkundig />

    <div>
      <v-img height="450" src="@/assets/overachtergrond.jpg">
        <div
          class="d-flex justify-center align-center"
          style="width: 100%; height: 100%"
        >
          <div class="kaart">
            <h2 class="mb-3">Resultaten begeleidingstrajecten</h2>
            <p>
              Ik heb jarenlange ervaring met het begeleiden en adviseren van
              individuen en groepen binnen diverse (non-) profitorganisaties met
              spraakmakende resultaten.
            </p>
            <div class="mt-2 d-flex justify-center flex-wrap" style="gap: 10px">
              <v-btn
                elevation="0"
                class="primary--text font-weight-bold"
                @click="openTab('./resultaten-mediations.pdf')"
                >Mediationtrajecten</v-btn
              >
              <v-btn
                elevation="0"
                class="primary--text font-weight-bold"
                @click="openTab('./resultaten-coaching.pdf')"
                >Coachingtrajecten</v-btn
              >
              <v-btn
                @click="openTab('./klantervaringen.pdf')"
                elevation="0"
                class="primary--text font-weight-bold"
                >Klantreviews</v-btn
              >
            </div>
          </div>
        </div>
      </v-img>
    </div>

    <Footer />
  </div>
</template>

<script>
import Vakkundig from "@/components/VakKundig.vue";
import Footer from "@/components/FooterBox.vue";

export default {
  components: { Footer, Vakkundig },
  methods: {
    openTab(url) {
      window.open(url, "_blank");
    },
  },
};
</script>

<style lang="scss" scoped>
.over {
  display: grid;
  grid-template-columns: 5fr 2fr;
  gap: 30px;
  margin: 25px;
  background-color: white;
  padding: 40px;
  max-width: 1200px;
  margin: 35px auto;
}

.kaart {
  background-color: rgba($color: #1f3e8e, $alpha: 0.9);
  max-width: 70%;
  color: white;
  text-align: center;
  display: flex;
  justify-content: center;
  align-content: center;
  padding: 25px;
  flex-direction: column;
  border-radius: 4px;
}

@media only screen and (max-width: 480px) {
  h1 {
    font-size: 16pt;
  }
  h2 {
    font-size: 14pt;
  }
  h3 {
    font-size: 12pt;
  }
  p {
    font-size: 10pt;
  }
  .subtitle {
    font-size: 12pt;
  }

  .over {
    grid-template-columns: 1fr;
    grid-template-rows: 2fr 1fr;
    width: 90vw;
  }

  .kaart {
    max-width: 90vw;
  }
}
</style>
