<template>
  <div>
    <v-img
      class="header text-center"
      height="30vh"
      min-height="250"
      src="@/assets/header2.png"
    >
      <div
        class="d-flex justify-center align-center flex-column"
        style="height: 100%; color: white"
      >
        <h1>Specialist in arbeidsmediation</h1>
        <p class="subtitle">
          <i>Brengt mens en organisatie in beweging</i>
        </p>
        <v-btn
          @click="$router.push('/contact')"
          elevation="0"
          class="primary--text font-weight-bold"
          >Contact</v-btn
        >
      </div>
    </v-img>

    <v-card flat class="over">
      <v-img
        style="border-radius: 3px"
        src="@/assets/jan10-1024x637.jpg"
      ></v-img>
      <div class="d-flex justify-center flex-column align-start">
        <h2 class="mb-3 primary--text">Over Jan Overmeer</h2>
        <p>
          Als MfN register arbeidsmediator met extra kennis en ervaring als
          register arbeidsdeskundige, master-practitioner NLP, gecertificeerd
          vertrouwenspersoon, ombudsman van de SRA en bedrijfsfysiotherapeut
          (niet-praktiserend), ben ik deskundig in het begeleiden bij conflicten
          eventueel gecombineerd met arbeidsongeschiktheid. Door effectieve
          analyses weet ik mensen met elkaar te verbinden, zodat de impasse
          doorbroken wordt. Deze doorbraak is de start van het reduceren van
          wrijving, conflicten, klachten of zelfs verzuim. Een professionele,
          integere en betrouwbare aanpak in combinatie met een praktische
          toepassing kenmerken mijn werkwijze, en hebben geleid tot
          <u
            class="primary--text"
            style="cursor: pointer"
            @click="openTab('./resultaten-mediations.pdf')"
            >meer dan 90% slagingspercentage</u
          >.
        </p>
        <v-btn
          @click="$router.push('/over')"
          color="button"
          class="primary--text font-weight-bold"
          elevation="0"
          >Lees meer</v-btn
        >
      </div>
    </v-card>

    <VakKundig />

    <div class="diensten">
      <h2 class="primary--text">Diensten</h2>
      <p>Mediation, coaching & training</p>
      <div class="subdiensten mt-8">
        <v-card flat class="pa-4">
          <img
            src="@/assets/mediation.jpg"
            style="
              width: 100%;
              aspect-ratio: 16/10;
              object-fit: cover;
              border-radius: 4px;
            "
          />
          <h3 class="primary--text">Mediation</h3>
          <p>
            Als deskundige MfN register mediator gespecialiseerd in
            arbeidsmediation begeleid ik mediation trajecten in de
            non-profitsector (onderwijs, zorg, overheid) en profitsector
            (commerciële sector, bank & verzekeringen, horeca, techniek,
            agrarische sector).
          </p>
          <v-btn
            elevation="0"
            color="button"
            class="primary--text font-weight-bold"
            @click="$router.push('/diensten?id=mediation')"
            >Lees meer</v-btn
          >
        </v-card>
        <v-card flat class="pa-4">
          <img
            src="@/assets/gespreksleider.jpg"
            style="
              width: 100%;
              aspect-ratio: 16/10;
              object-fit: cover;
              border-radius: 4px;
            "
          />
          <h3 class="primary--text">Neutrale gespreksleider</h3>
          <p>
            Als neutrale gespreksleider maak ik moeilijk bespreekbare
            onderwerpen, eventueel in combinatie met arbeidsongeschiktheid,
            bespreekbaar. Via kompasgesprekken streven we samen naar de juiste
            koers.
          </p>
          <v-btn
            @click="$router.push('/diensten?id=gespreksleider')"
            elevation="0"
            color="button"
            class="primary--text font-weight-bold"
            >Lees meer</v-btn
          >
        </v-card>
        <v-card flat class="pa-4">
          <img
            src="@/assets/coaching.png"
            style="
              width: 100%;
              aspect-ratio: 16/10;
              object-fit: cover;
              border-radius: 4px;
            "
          />
          <h3 class="primary--text">Coaching</h3>
          <p>
            Als betrokken coach staat een aanpak op basis van gelijkwaardigheid,
            respect, empathie en vertrouwen centraal. Door een actieve
            coachingstijl werken we samen aan het vergroten van (zelf-)inzicht,
            bewustwording van patronen en stimuleren van effectief gedrag.
          </p>
          <v-btn
            @click="$router.push('/diensten?id=coaching')"
            elevation="0"
            color="button"
            class="primary--text font-weight-bold"
            >Lees meer</v-btn
          >
        </v-card>
        <v-card flat class="pa-4">
          <img
            src="@/assets/training.jpg"
            style="
              width: 100%;
              aspect-ratio: 16/10;
              object-fit: cover;
              border-radius: 4px;
            "
          />
          <h3 class="primary--text">Training</h3>
          <p>
            Voor medewerkers met een verschil van inzicht, een (dreigend)
            conflict of niet optimale samenwerking is een verbindende trainer de
            sleutel tot een sterkere samenwerking te komen.
          </p>
          <v-btn
            @click="$router.push('/diensten?id=training')"
            elevation="0"
            color="button"
            class="primary--text font-weight-bold"
            >Lees meer</v-btn
          >
        </v-card>
      </div>
    </div>

    <div class="kernwaarden">
      <h2 class="primary--text">Kernwaarden Jan Overmeer</h2>
      <p>Verbinder in de driehoek van mens, arbeid & organisatie.</p>
      <div class="kaarten mt-8">
        <v-card flat class="px-4 py-8">
          <v-avatar size="60">
            <img src="@/assets/logo.jpg" style="width: 100%" />
          </v-avatar>
          <h3 class="primary--text">Betrouwbaar</h3>
          <p style="max-width: 80%; margin: auto">
            Eerlijkheid en een vertrouwelijke werkwijze staan centraal.
          </p>
        </v-card>
        <v-card flat class="px-4 py-8">
          <v-avatar size="60">
            <img src="@/assets/logo.jpg" style="width: 100%" />
          </v-avatar>
          <h3 class="primary--text">Empathisch</h3>
          <p style="max-width: 80%; margin: auto">
            Inlevingsvermogen om tot de kern van de issue of persoon te komen.
          </p>
        </v-card>
        <v-card flat class="px-4 py-8">
          <v-avatar size="60">
            <img src="@/assets/logo.jpg" style="width: 100%" />
          </v-avatar>
          <h3 class="primary--text">Veelzijdig</h3>
          <p style="max-width: 80%; margin: auto">
            Brede kennis en ervaring, en wendbaar naar de situatie.
          </p>
        </v-card>
      </div>
    </div>

    <div class="klanten white">
      <h2 class="mb-2 primary--text">Een greep uit mijn klantenbestand</h2>
      <p>Verbinder in de driehoek mens, arbeid & organisatie.</p>
      <v-btn
        elevation="0"
        class="primary--text font-weight-bold"
        color="button"
        @click="openTab('./klantervaringen.pdf')"
        >Bekijk hier de klantervaringen</v-btn
      >
      <div class="logos mt-8">
        <v-img contain src="@/assets/amphia.jpg"></v-img>
        <v-img contain src="@/assets/entrea.jpg"></v-img>
        <v-img contain src="@/assets/otolift.png"></v-img>
        <v-img contain src="@/assets/vgz.png"></v-img>
        <v-img contain src="@/assets/cwz.jpeg"></v-img>
        <v-img contain src="@/assets/bakker.webp"></v-img>
      </div>
    </div>

    <Footer />
  </div>
</template>

<script>
import VakKundig from "@/components/VakKundig.vue";
import Footer from "@/components/FooterBox.vue";

export default {
  name: "HomeView",
  components: { Footer, VakKundig },
  methods: {
    openTab(url) {
      window.open(url, "_blank");
    },
  },
};
</script>

<style lang="scss" scoped>
.subtitle {
  font-size: 16pt;
}
.over {
  display: grid;
  grid-template-columns: 2fr 3fr;
  gap: 30px;
  max-width: 1200px;
  margin: 25px auto;
  background-color: white;
  padding: 40px;
}

.diensten {
  text-align: center;
  padding: 40px 0;
  max-width: 1200px;
  margin: 25px auto;

  .subdiensten {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 15px;

    h3 {
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }
}

.kernwaarden {
  padding: 40px 0;
  text-align: center;
  max-width: 1200px;
  margin: 25px auto;

  .kaarten {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 25px;

    h3 {
      margin-top: 20px;
      margin-bottom: 10px;
    }
  }
}

.klanten {
  padding: 40px;
  text-align: center;
  max-width: 1200px;
  margin: 70px auto;

  .logos {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    gap: 15px;
    height: 100px;
  }
}

@media only screen and (max-width: 480px) {
  h1 {
    font-size: 16pt;
  }
  h2 {
    font-size: 14pt;
  }
  h3 {
    font-size: 12pt;
  }
  p {
    font-size: 10pt;
  }
  .subtitle {
    font-size: 12pt;
  }

  .over {
    padding: 25px;
    max-width: 90vw;
    display: grid;
    grid-template-rows: 2fr 3fr;
    grid-template-columns: 1fr;
  }

  .diensten {
    max-width: 90vw;
    .subdiensten {
      grid-template-rows: 1fr 1fr;
      grid-template-columns: 1fr 1fr;
    }
  }

  .kernwaarden {
    margin: 0;

    .kaarten {
      grid-template-rows: 1fr 1fr 1fr;
      grid-template-columns: 1fr;
      max-width: 90vw;
      margin: auto;
    }
  }

  .klanten {
    width: 90vw;
    .logos {
      grid-template-rows: 1fr 1fr;
      grid-template-columns: 1fr 1fr 1fr;
    }
  }
}
</style>
