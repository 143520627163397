<template>
  <div>
    <v-img class="text-center mb-8" height="250" src="@/assets/header2.png">
      <div
        class="d-flex white--text justify-center align-center flex-column"
        style="height: 250px"
      >
        <h1>Benieuwd wat ik voor je kan betekenen?</h1>
        <p class="subtitle">
          <i>Neem vrijblijvend contact op</i>
        </p>
      </div>
    </v-img>

    <div class="contact">
      <v-card flat class="pa-4 d-flex justify-center align-center flex-column">
        <v-icon class="mb-4" color="primary" x-large>mdi-phone</v-icon>
        <h3 class="mb-2">Telefonisch</h3>
        <p class="mb-3"><a href="tel:0646867469">06-46867469</a></p>
        <a href="tel:0646867469">
          <v-btn
            color="button"
            class="primary--text font-weight-bold"
            elevation="0"
            >Bel mij</v-btn
          >
        </a>
      </v-card>
      <v-card flat class="pa-4 d-flex justify-center align-center flex-column">
        <v-icon class="mb-4" color="primary" x-large>mdi-email</v-icon>
        <h3 class="mb-2">Email</h3>
        <p class="mb-3"><u>info@janovermeer.nl</u></p>
        <v-btn
          @click="openTab('mailto:info@janovermeer.nl?subject=Contactverzoek')"
          color="button"
          class="primary--text font-weight-bold"
          elevation="0"
          >Email mij</v-btn
        >
      </v-card>
      <v-card flat class="pa-4 d-flex justify-center align-center flex-column">
        <v-icon class="mb-4" color="primary" x-large>mdi-linkedin</v-icon>
        <h3 class="mb-3">LinkedIn</h3>

        <v-btn
          @click="openTab('https://www.linkedin.com/in/janovermeer/')"
          color="button"
          class="primary--text font-weight-bold"
          elevation="0"
          >Bekijk mijn profiel</v-btn
        >
      </v-card>
    </div>

    <div class="overige primary mt-10">
      <h3 class="mb-2">Overige informatie</h3>
      <div
        class="mb-2"
        style="cursor: pointer"
        @click="openTab('./algemenevoorwaarden.pdf')"
      >
        <v-icon color="white" small class="mr-1">mdi-arrow-right</v-icon>
        <u> Algemene voorwaarden</u>
      </div>
      <div
        class="mb-2"
        style="cursor: pointer"
        @click="openTab('./privacy.pdf')"
      >
        <v-icon color="white" small class="mr-1">mdi-arrow-right</v-icon>
        <u>Privacy beleid</u>
      </div>
      <div style="cursor: pointer" @click="openTab('./Privacyverklaring.pdf')">
        <v-icon color="white" small class="mr-1">mdi-arrow-right</v-icon>
        <u>Privacyverklaring</u>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  methods: {
    openTab(url) {
      window.open(url, "_blank");
    },
  },
};
</script>

<style lang="scss" scoped>
.contact {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
  max-width: 1000px;
  margin: 5% auto;
}

.overige {
  text-align: center;
  padding: 40px;
  color: white;
}
.subtitle {
  font-size: 16pt;
}

@media only screen and (max-width: 480px) {
  h1 {
    font-size: 16pt;
    max-width: 80vw;
    margin-bottom: 10px;
  }
  h2 {
    font-size: 14pt;
  }
  h3 {
    font-size: 12pt;
  }
  p {
    font-size: 10pt;
  }
  .subtitle {
    font-size: 12pt;
  }

  .contact {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    max-width: 90vw;
  }
}
</style>
