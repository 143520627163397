import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: "mdi",
  },
  theme: {
    themes: {
      light: {
        primary: "#1F3E8E",
        secondary: "#EBEFFA",
        accent: "#cfb8ff",
        error: "#fa7a68",
        info: "#ffd978",
        success: "#c9fa75",
        button: "#C6D3F3",
        warning: "#b6b6b6",
        smoke: "#f0f0f0",
        dark: "#191919",
        facebook: "#1877F2",
        instagram: "#C13584",
        pink: "#E2BAC2",
      },
    },
  },
});
